<template>
  <el-dialog v-model="shouldShow" title="Lista de contatos enviados">
    <div v-if="hasError">
      <el-row type="flex" align="center" justify="center">
        <h4>Ocorreu um erro a obter os dados do envio</h4>
      </el-row>
    </div>
    <el-loading v-loading="isLoading" v-else-if="isLoading"> </el-loading>
    <el-row v-for="c in bid?.coms" :key="c" type="flex" justify="space-between">
      <el-col :md="16" align="left">
        <el-row type="flex" justify="start"
          ><span>{{ c.contact.name }}</span></el-row
        >
        <el-row type="flex" justify="start"
          ><h6 class="text-muted">
            {{ c.contact.phone }}
          </h6></el-row
        >
      </el-col>

      <i class="el-icon-circle-check my-auto received" v-if="isReceived(c)"></i>
      <i class="el-icon-more my-auto unreceived" v-else></i>
    </el-row>
  </el-dialog>
</template>
<script>
import { ElLoading } from "element-plus";
export default {
  props: ["bid_uid", "showModal"],
  emits: ["close-modal"],
  components: { ElLoading },
  data() {
    return {
      isLoading: true,
      hasError: false,
      bid: null,
    };
  },
  watch: {
    showModal(v) {
      if (v) {
        this.isLoading = true;
        this.fetchBidSent();
      }
    },
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    isReceived(contact) {
      return !!contact?.received_at;
    },
    markOrUnmarkAllContacts() {
      if (this.selectAll)
        this.markedContacts = this.contacts?.reduce((t, e) => {
          t[e.uid] = true;
          return t;
        }, {});
      else this.markedContacts = {};
    },
    fetchBidSent() {
      const url = new URL(
        `${this.$store.state.apiUrl}bids_sent/${this.bid_uid}`
      );
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.bid = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
  name: "SendDetails",
};
</script>
<style>
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.text-muted {
  color: grey !important;
  margin-top: 5px !important;
}
.received {
  color: green;
}
.unreceived {
  color: grey;
}
</style>