<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Ofertas enviadas</h4>
        <!--<el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openBidModal(null)"
            ></el-button>
          </el-row>
        </el-col>-->
      </el-row>
    </template>
    <el-table
      stripe
      :data="bids"
      style="width: 100%; z-index: 0"
      :cell-style="() => 'text-align:center;'"
    >
      <el-table-column prop="bid.title" label="título"> </el-table-column>
      <el-table-column
        label="mensagem"
        :formatter="
          (r) =>
            r?.bid?.message?.slice(0, 20) +
            (r?.bid?.message?.length > 20 ? '...' : '')
        "
      >
      </el-table-column>
      <el-table-column label="envios">
        <template #default="r">
          {{ showBidsSent(r.row) }}
        </template>
      </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openBidModal(cat.row)"
              >
                <i class="el-icon-info"></i
              ></el-button>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <bid-send-details
      :showModal="showBidSendDetailsModal"
      :bid_uid="bid?.uid"
      @close-modal="showBidSendDetailsModal = false"
    ></bid-send-details>
  </el-card>
</template>

<script>
import BidSendDetails from "./modals/SendDetails.vue";

export default {
  name: "bidsPage",
  components: { BidSendDetails },
  data: () => ({
    hasError: false,
    isLoading: true,
    showBidSendDetailsModal: false,
    bids: null,
    bid: null,
    showBidModal: false,
  }),
  mounted() {
    this.fetchBids();
  },
  methods: {
    openBidModal(e) {
      (this.bid = e), (this.showBidSendDetailsModal = true);
    },
    showBidsSent(bid) {
      return `${bid.coms?.filter((c) => c.received_at)?.length || 0}/${
        bid.coms?.length || 0
      }`;
    },
    fetchBids() {
      fetch(`${this.$store.state.apiUrl}bids_sent`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.bids = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>